import React from "react"
import { css } from "@emotion/core"
import SectionHeader from "./headers/section-header"
import _ from "lodash"
import GatsbyImage from "gatsby-image"
import TapeLabelHeader from "./headers/tape-label-header"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const EtsyItems = ({ title, items }) => {
  return (
    <div>
      <SectionHeader>{title}</SectionHeader>
      <div css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
      `}>
        {_.map(items, ({ node }) => {
          return (

            <div
              key={node.url} css={css`
              max-width: 400px;
              flex: 0 100%;
              text-align: center;
              @media screen and (min-width: 480px) {
                flex: 0 50%
              }
              @media screen and (min-width: 960px) {
                flex: 0 33%
              }
`}>
              <OutboundLink
                href={node.url}>
                <div css={css`position:relative; margin-bottom:80px; padding: 20px;`}>
                  <span
                    className={"drop-shadow"}
                    css={css`
                      background-color: black;
                      color:white;
                      font-family: Special Elite, serif;
                      padding: 10px 10px 5px;
                      font-size:1.5em;
                      display:inline-block;
                      position:absolute;
                      border:1px dashed silver;
                      z-index:1;
                      right:0;
                      top:0;`}
                  >${node.price}</span>

                  <GatsbyImage fluid={node.childrenEtsyListingImage[0].childFile.childImageSharp.fluid} alt={node.title}
                               css={css`filter: drop-shadow(3px 3px 5px #101010);`}/>
                  <TapeLabelHeader>{_.unescape(node.title)}</TapeLabelHeader>
                </div>
              </OutboundLink>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default EtsyItems