import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import _ from "lodash"
import PageHeader from "../components/headers/page-header"
import EtsyItems from "../components/etsy-items"
import ContentBox from "../components/content-box"
import SectionHeader from "../components/headers/section-header"
import fetchJsonp from "fetch-jsonp"

class PageShop extends React.Component {

  componentDidMount() {

    (async function () {
      console.log('componentDidMount')
      // TODO: remove api_key, add to config
      let test = await fetchJsonp('https://openapi.etsy.com/v2/listings/762456707/inventory.js?api_key=4gi9qjnk3rhzuith0kl37g1a')
      let json = await test.json()
      console.log(json)
      // TODO: get quantities of each item
    })()

  }

  render() {
    const { data } = this.props

    const comics = _.orderBy(
      _.filter(data.allFeaturedEtsyListing.edges,
        ({ node }) => node.category_path.includes("Comic")),
        [({node}) => {return node.childrenEtsyListingImage[0].full_width / node.childrenEtsyListingImage[0].full_height}, 'node.title'],
      ["asc"])
    const clothing = _.orderBy(_.filter(data.allFeaturedEtsyListing.edges, ({ node }) => node.category_path.includes("Clothing")), ["node.title"])
    const prints = _.filter(data.allFeaturedEtsyListing.edges, ({ node }) => node.category_path.includes("Print"))
    const stickers = _.filter(data.allFeaturedEtsyListing.edges, ({ node }) => node.category_path.includes("Tag"))
    // TODO: pins

    // TODO: remove as we filter each

    return (
      <Layout location={this.props.location}>
        <SEO
          title={'Shop'}
          description={`Support us! Buy original clothing, comics, graphic novels, stickers, pins, posters and more from Paranoid American!`}
        />

        {/* TODO: call to action */}

        <PageHeader>Shop</PageHeader>
        <EtsyItems title={"Comics"} items={comics}/>
        <EtsyItems title={"Clothing"} items={clothing}/>
        <EtsyItems title={"Prints & Posters"} items={prints}/>
        <EtsyItems title={"Stickers & Pins"} items={stickers}/>

        <PageHeader>Shipping & Returns</PageHeader>
        <ContentBox>
          <SectionHeader align={"left"}>Shipping</SectionHeader>
          <strong>Note:</strong> Items take 3-5 days after purchase before shipping. Will ship these out as fast as
          possible
          but until we have a solid distributor, all comic orders are usually sent out on personally on weekends.
          All items below are currently sold via etsy.com

          <SectionHeader align={"left"}>Returns</SectionHeader>
          Paranoid American Inc has a <strong>no-return policy</strong>. However, if you have any problem at
          all with an order please contact us at <a
          href={"mailto:contact@paranoidamerican.com"}>contact@paranoidamerican.com</a> and we'll do our best to set it
          right.
        </ContentBox>

      </Layout>
    )
  }
}

export default PageShop

export const pageQuery = graphql`
    query {
        allFeaturedEtsyListing {
            totalCount
            edges {
                node {
                    title
                    category_path
                    url
                    price
                    childrenEtsyListingImage {
                        full_height
                        full_width
                        childFile {
                            childImageSharp {
                                fluid(maxWidth: 360) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }


    }
`
